<template>
  <div>
    <div class="text-center" v-if="!sucesso">
      <v-icon class="tw-animate-spin tw-text-pink-600" size="50"
        >mdi-loading</v-icon
      >
    </div>
    <div v-else>
      <div class="tw-px-9 tw-py-1 tw-items-center tw-justify-left tw-flex">
        <button
          @click="goBack()"
          class="tw-bg-transparent tw-rounded-full tw-fill-current tw-group tw-text-text-gray-700 tw-text-xl"
        >
          <v-icon
            size="25"
            color="fill"
            class="tw-text-gray-700 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
            >mdi-arrow-left</v-icon
          >
          <div
            class="tw-inline-block tw-text-gray-500 tw-font-light tw-text-base tw-ml-2"
          >
            VOLTAR
          </div>
        </button>
      </div>

      <div class="tw-flex tw-items-center tw-text-center tw-p-5 tw-bg-gray-100">
        <div class="tw-px-8">
          <v-icon
            class="tw-absolute tw-animate-ping tw-text-green-500 tw-stroke-current"
            >mdi-circle</v-icon
          >
          <v-icon class="tw-text-green-500 tw-stroke-current"
            >mdi-circle</v-icon
          >
        </div>
        <div class="tw-p-4 tw-px-3 sm:tw-px-5 tw-text-left">
          <div class="tw-font-bold tw-text-gray-600 tw-text-lg">
            {{ getTotem.nome }}
          </div>
          <div class="tw-text-gray-500">{{ getTotem.descricao }}</div>
        </div>
      </div>
      <div class="tw-my-5 tw-text-center sm:tw-text-left">
        <button
          class="tw-p-3 tw-mx-auto sm:tw-mx-5 tw-text-white tw-rounded-lg tw-bg-gray-700 hover:tw-bg-gray-600 tw-transition-colors tw-duration-300"
          @click="gotoReg()"
        >
          Registros
        </button>
      </div>
      <div id="CartAtual">
        <div
          class="tw-p-2 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-justify-center tw-items-center tw-text-center"
          v-if="getTotem.compra_atual.id"
        >
          <div class="cardCart">
            <div>Etapa</div>
            <div class="tw-text-pink-600 tw-font-bold">
              {{ getTotem.compra_atual.etapa | filtraEtapa }}
            </div>
          </div>

          <div class="cardCart">
            <div>Ultima atualização</div>
            <div class="tw-text-pink-600 tw-font-bold">
              <div class="tw-py-1">
                {{ getTotem.keep_alive.toDate() | getDataPayload }}
              </div>
            </div>
          </div>
          <div class="cardCart">
            <div>Quantidade fotos</div>
            <div class="tw-text-pink-600 tw-font-bold">
              <div class="tw-py-1">
                {{ getTotem.compra_atual.qtde_foto }}
              </div>
            </div>
          </div>
          <div class="cardCart">
            <div>Valor da compra</div>
            <div class="tw-text-pink-600 tw-font-bold">
              <div class="tw-py-1">
                {{ getTotem.compra_atual.valor }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { etapaName } from "@/constants/etapaCompra";

export default {
  beforeMount() {
    this.verificaExistenciaLocal();
  },
  data() {
    return {
      countFail: 0,
      sucesso: false,
    };
  },
  filters: {
    getDataPayload(val) {
      return moment(val).format("DD/MM/YYYY - HH:mm:ss");
    },
    filtraEtapa(valor) {
      return etapaName[valor];
    },
  },
  methods: {
    goBack() {
      this.$router.push("/dashboard/totem/lista-totem");
    },
    gotoReg() {
      this.$router.push(`/dashboard/totem/${this.$route.params.id}/compras`);
    },
    verificaExistenciaLocal() {
      let totens = this.$store.getters["dashboard/getTotens"];
      let totemSearch = totens.find((item) => item.id == this.$route.params.id);
      if (totemSearch) {
        this.sucesso = true;
      } else {
        if (this.countFail > 10) {
          this.$root.infoModal("Totem ou permissão não encontrada");
          this.$router.push("/dashboard/totem/lista-totem");
        } else {
          setTimeout(() => {
            this.verificaExistenciaLocal();
          }, 500);
        }
      }
    },
  },
  computed: {
    getTotem() {
      return this.$store.getters["dashboard/getTotens"].find(
        (item) => item.id == this.$route.params.id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.cardCart {
  @apply tw-px-3 tw-py-5 tw-w-full sm:tw-w-4/5 sm:tw-max-w-md  tw-shadow-lg tw-m-2 tw-mx-auto tw-bg-white tw-rounded-lg tw-col-span-1;
}
</style>
